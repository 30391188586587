import React from "react"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"

import { Colors } from "../../utils/constants"
import LinkButton from "../LinkButton/LinkButton"
import Image from "../Image/Image"

// ✅ => on desktop everything needs to be aligned to left by default
// ✅ => on mobile the content needs to be aligned to left and the image needs to be centred by default
// ✅ => on desktop everything needs to be centered vertically
// ✅ => create separate mobile props to specify custom design
// ✅ => refactor isRverse
// 𐄂 => minimize props as much as possible
// 𐄂 => refactor isCentred or anything related to it
// 𐄂 => make the component self suffisant and give it all the margin/padding needed

const Paragraph = ({
  children,
  title,
  content,
  img,
  fixedSources,
  link,
  ...props
}) => {
  return (
    <StyledParagraphContainer {...props}>
      {(title || content || link || fixedSources) && (
        <StyledContent {...props}>
          {(title || content || link) && (
            <StyledTextContainer {...props}>
              {title && <h1>{title}</h1>}
              {content && <p>{content}</p>}
              {link && (
                //TODO: why are we using a container?
                <StyledLinkButtonContainer {...props}>
                  <LinkButton href={link.href} theme="red">
                    {link.label}
                  </LinkButton>
                </StyledLinkButtonContainer>
              )}
            </StyledTextContainer>
          )}
          {(img || fixedSources) && (
            <StyledImage {...props}>
              <Image
                fluid={img}
                fixedSources={fixedSources}
                alt="Paragraph image"
              />
            </StyledImage>
          )}
        </StyledContent>
      )}
      {children}
    </StyledParagraphContainer>
  )
}

const StyledParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  align-items: ${props => (props.centerV ? "center" : null)};
  flex-direction: ${props =>
    props.mobile?.isReversed ? "column" : "column-reverse"};
  p {
    line-height: 2;
  }
  ${media.md`
    flex-direction: ${props => (props.isReversed ? "row-reverse" : "row")};
    align-items: center;
    p {
      line-height: normal;
    }
  `}
`

const StyledTextContainer = styled.div`
  flex: 1;
  /* text-align: ${props =>
    props.contentLeftOnMobile ? "left" : props.centerH ? "center" : "left"}; */
  text-align: left;
  h1,
  h2 {
    font-family: arial;
    text-transform: uppercase;
  }
  h1 {
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.RED};
  }
  p {
    font-size: 12px;
    font-weight: ${props => (props.isContentBoldAndBlack ? "bold" : "normal")};
    margin: 25px 0px;
    color: ${props =>
      props.isContentBoldAndBlack ? Colors.BLACK : Colors.GREY_5};
  }
  ${media.md`
    h1{
      font-size: 30px;
    }
    p {
      font-size: 18px;
      margin: 50px 0px;
    }
  `}
`

const StyledLinkButtonContainer = styled.div`
  display: flex;
  ${({ centerH }) => (centerH ? `justify-content: center;` : "")}
  ${media.md`
    /* justify-content:${props =>
      props.mobile?.leftAlignContentOnMd && "flex-start"}; */
  `};
`

const StyledImage = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  padding: 20px 0;
  .gatsby-image-wrapper {
    border-radius: ${props => (props.isRounded ? "50%" : "4px")};
    height: ${props => (props.isSmall ? "100%" : "50vh")};
    width: ${props =>
      props.isSmall
        ? "50%"
        : props.imageNotFullWidthOnMobile
        ? "90vw"
        : "100%"};
    max-width: 100vw;
    max-height: 80vh;
    object-fit: cover;
  }
  ${media.md`
    text-align: ${props => (props.isReversed ? "left" : "right")};
    justify-content: ${props => (props.isReversed ? "flex-start" : "flex-end")};
    .gatsby-image-wrapper {
      max-width: 424px;
      max-height: 430px;
    }
  `}
`

export default Paragraph
