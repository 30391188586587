import React from "react"
import { Col, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import Image from "../Image/Image"
import MediaObjectGrid from "../MediaObjectGrid/MediaObjectGrid"

const ValuesGrid = ({
  title,
  mediaObjects,
  link,
  image,
  fixedSources,
  isReversed
}) => {
  return (
    <div>
      <Row>
        <Col col="12" hiddenMdUp>
          <StyledTitle>{title}</StyledTitle>
        </Col>
        <Col col="12">
          <StyledValuesContainer isReversed={isReversed}>
            <StyledContent md="6" isReversed={isReversed}>
              <StyledTitle>{title}</StyledTitle>
              <MediaObjectGrid
                mobileCol="2"
                link={link}
                mediaObjects={mediaObjects}
              />
            </StyledContent>
            <StyledImageContainer isReversed={isReversed} col="12" md="6">
              <StyledImage
                fluid={image}
                fixedSources={fixedSources}
                alt="Values image"
              />
            </StyledImageContainer>
          </StyledValuesContainer>
        </Col>
      </Row>
    </div>
  )
}

const StyledImageContainer = styled(Col)`
  display: flex;
  .gatsby-image-wrapper {
    height: auto;
  }
  ${media.md`
  justify-content: ${({ isReversed }) =>
    isReversed ? "flex-start" : "flex-end"};
    .gatsby-image-wrapper {
      height: 100% !important;
    }
    `}
`
const StyledContent = styled(Col)`
  ${media.md`
   padding-left : ${props => (props.isReversed ? "135px" : "0px")};
  `}
`
const StyledTitle = styled.h1`
  text-transform: uppercase;
  font-size: 16px;
  padding: 0px 15px;
  font-weight: bold;
  color: ${Colors.RED};
  margin-bottom: 30px;
  ${media.md`
      font-size: 30px;
      margin : 0px;
      padding: 0px;
  `}
`

const StyledImage = styled(Image)`
  border-radius: 5px;
  ${media.md`
    max-width: 543px;
  `}
`

const StyledValuesContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  h1 {
    display: none;
  }
  & > div:last-of-type {
    width: 100%;
    height: auto;
  }
  ${media.md`
    flex-direction: ${props => (props.isReversed ? "row-reverse" : "row")};
    justify-content: space-between;
    & > div:first-of-type {
      flex-basis: 70%;
    }
    h1 {
      display: block;
    }
    & > div:last-of-type {
      text-align: ${props => (props.isReversed ? "left" : "right")};
    }
  `}
`

export default ValuesGrid
