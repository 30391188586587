import React from "react"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import LinkButton from "../LinkButton/LinkButton"

const MediaObject = ({ icon, title, description, link, ...props }) => {
  return (
    <StyledMediaContainer {...props}>
      {icon && <img src={icon} alt="media" />}
      {(title || description) && (
        <div>
          {title && <h2>{title}</h2>}
          {description && <p>{description}</p>}
        </div>
      )}
      {link && (
        <StyledLinkContainer>
          <LinkButton theme={link.theme} stretched href={link.href}>
            {link.label}
          </LinkButton>
        </StyledLinkContainer>
      )}
    </StyledMediaContainer>
  )
}

const StyledMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  background-color: ${props => props.backgroundColor};
  img {
    align-self: flex-start;
  }
  div:first-of-type {
    line-height: 1.5;
    h2 {
      font-weight: bold;
      color: ${props => props.titleColor || Colors.GREY_5};
      padding: 10px 0px;
      margin: 0;
      font-size: 14px;
    }
    p {
      font-size: 14px;
      color: ${Colors.GREY_4};
      padding-bottom: 40px;
      margin: 0;
    }
  }
`

const StyledLinkContainer = styled.div`
  margin-top: auto;
`

export default MediaObject
