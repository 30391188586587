import React from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"
import PropTypes from "prop-types"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import closeIcon from "../../images/close-icon.svg"

import { Colors } from "../../utils/constants"

const Slider = ({ images = [] }) => {
  return (
    <div>
      <StyledCloseIcon>
        <img src={closeIcon} alt="close" />
      </StyledCloseIcon>
      <StyledCarousel
        showArrows={false}
        showThumbs={false}
        statusFormatter={() => ""}
        dynamicHeight
      >
        {images.map((item, index) => (
          <div key={index}>
            <img src={item.imageUrl} alt={item.title} />
          </div>
        ))}
      </StyledCarousel>
    </div>
  )
}

Slider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
  onClose: PropTypes.func,
}

const StyledCarousel = styled(Carousel)`
  .control-dots {
    top: 0px;
    ${media.md`
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      right: 0px;
      width: unset;
      bottom: unset;
      text-align: unset;
      margin: 0;
    `};
    .dot {
      background-color: ${Colors.GREY_8};
    }
    .dot.selected {
      background-color: ${Colors.BLACK};
    }
  }
  .slide {
    background-color: ${Colors.GREY_9};
    height: 10vh;
  }

  .slide > div > img {
    height: 100%;
    width: 100%;
  }
`

const StyledCloseIcon = styled.div`
  position: absolute;
  background-color: ${Colors.RED};
  border-radius: 50%;
  cursor: pointer;
  padding: 8px 12px;
  z-index: 1;
  top: 36px;
  right: 18px;
`
export default Slider
