import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"

const Card = ({
  children,
  description,
  title,
  childrenPosition,
  backgroundColor = Colors.PINK,
  isVertical,
  isChildrenOnBottomEdge,
  isCentred,
  titleColor = Colors.RED_1,
  textColor = Colors.BLACK,
  isNarrow,
  height,
}) => {
  return (
    <>
      {(title || description) && (
        <StyledCard
          isVertical={isVertical}
          backgroundColor={backgroundColor}
          isChildrenOnBottomEdge={isChildrenOnBottomEdge}
          isCentred={isCentred}
          titleColor={titleColor}
          textColor={textColor}
          isNarrow={isNarrow}
          height={height}
        >
          <div>
            {title && <h2>{title}</h2>}
            {description && <p>{description}</p>}
          </div>
          <StyledChildren
            childrenPosition={childrenPosition}
            isChildrenOnBottomEdge={isChildrenOnBottomEdge}
            isVertical={isVertical}
          >
            {children}
          </StyledChildren>
        </StyledCard>
      )}
    </>
  )
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: ${props => (props.isNarrow ? "row" : "column")};
  justify-content: ${props =>
    props.isChildrenOnBottomEdge ? "unset" : "space-between"};
  align-items: ${props => (props.isNarrow ? "center" : null)};
  background-color: ${props => props.backgroundColor};
  border-radius: 10px;
  text-align: ${props => (props.isCentred ? "center" : "left")};
  padding: ${props => (props.isNarrow ? "0 16px 0 16px" : "24px")};
  padding-bottom: ${props => (props.isChildrenOnBottomEdge ? "0px" : null)};
  line-height: ${props => (props.isNarrow ? 1 : 2)};
  margin-bottom: 10px;
  & > div:first-of-type {
    display: flex;
    justify-content: ${props => (props.isCentred ? "center" : "space-between")};
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;
    flex: ${props => (props.isChildrenOnBottomEdge ? 0 : 2)};
  }
  h2 {
    font-size: 14px;
    color: ${props => props.titleColor};
    line-height: 30px;
  }
  p {
    font-size: 12px;
    color: ${props => props.textColor};
  }

  ${media.md`
  height: ${props => props.height || "auto"};
  padding: ${props =>
    props.isNarrow ? "0 16px" : props.isChildrenOnBottomEdge && "24px"}; 
    flex-direction: ${props => (props.isVertical ? "column" : "row")};
    & > div:first-of-type {
      flex: ${props => (props.isChildrenOnBottomEdge ? 0 : 1)};
    }
    h2 {
      font-size: 18px;
      margin: 6px 0;
    }
    p {
      font-size: 14px;
      margin: ${props => props.isNarrow && "8px 0px 14px 0px"} ;  
    }
  `}
`

const StyledChildren = styled.div`
  display: flex;
  align-items: ${props => props.childrenPosition};
  justify-content: center;
  flex: 1;
  img {
    width: 33px;
    height: 33px;
    margin-right: 8px;
    align-self: center;
  }
  a {
    width: ${props => (props.isVertical ? "100%" : null)};
  }
  ${media.md`
    justify-content: center;
  `}
`

export default Card
