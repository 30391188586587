import React from "react"
import { media, Row, Container, Col } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import linkedIn from "../../images/linkedin.svg"
import linkedInHover from "../../images/linkedin_hover.svg"
import LinkButton from "../LinkButton/LinkButton"
import Image from "../Image/Image"

const BusinessCard = ({
  position,
  title,
  content,
  socialMedia,
  img,
  isReversed,
  ...props
}) => {
  return (
    <Container>
      <CardRow isReversed={isReversed}>
        <Col md="4">
          <ImageRow isReversed={isReversed}>
            <Col col="4" md="9">
              {img && <Image fluid={img} alt="Team member image" />}
              {socialMedia && (
                <LinkButton
                  href={socialMedia.link}
                  icon={linkedIn}
                  hoverIcon={linkedInHover}
                />
              )}
            </Col>
            <StyledTitle isReversed={isReversed} hiddenLgUp col="8">
              {title && <h1>{title}</h1>}
              {position && <h2>{position}</h2>}
            </StyledTitle>
          </ImageRow>
        </Col>
        <Col md="8">
          <Row>
            <Col hiddenLgDown>
              {title && <h1>{title}</h1>}
              {position && <h2>{position}</h2>}
            </Col>
            <Col>{content && <p>{content}</p>}</Col>
          </Row>
        </Col>
      </CardRow>
    </Container>
  )
}

const StyledTitle = styled(Col)`
  text-align: ${props => (props.isReversed ? "left" : "right")};
`
const ImageRow = styled(Row)`
  position: relative;
  flex-direction: ${props => (props.isReversed ? "row-reverse" : "row")};
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
  }
  a {
    border: none;
    position: absolute;
    ${props => (props.isReversed ? "left: -45px" : "right: -45px")};
    top: 20%;
    img {
      max-width: 40px;
    }
  }
  ${media.md`
  justify-content: flex-start;
  img {
  max-width: 100%;
  }
  a{
    ${props => (props.isReversed ? "left: -50px" : "right: -50px")};
    img {
      max-width: 60px;
    }
  }
  `}
  ${media.lg`
  a{
    ${props => (props.isReversed ? "left: -60px" : "right: -60px")};
    img {
      max-width: 150px;
    }
  }
  `}
  ${media.xl`
  a{
    top: 30%;
    ${props => (props.isReversed ? "left: -60px" : "right: -60px")};
      img {
      max-width: 170px;
    }
  }
  `}
`
const CardRow = styled(Row)`
  flex-direction: ${props => (props.isReversed ? "row-reverse" : "row")};
  h1,
  h2 {
    text-transform: uppercase;
  }
  h1 {
    font-size: 14px;
    color: ${Colors.BLACK};
  }
  h2 {
    font-size: 12px;
    color: ${Colors.RED_2};
  }
  p {
    clear: both;
    line-height: 2;
    font-size: 12px;
    color: ${Colors.GREY_4};
  }
  ${media.md`
        h1 {
            font-size: 30px;
            font-weight: bold;
        }
        h2 {
            font-size: 18px;
            font-weight: bold;
        }
        p {
            line-height: normal;
            font-size: 18px;
            font-weight: normal;
        } 
    `}
`

export default BusinessCard
