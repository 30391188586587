import React from "react"
import styled from "styled-components"

import ArrowDownIcon from "../../images/arrow-down.svg"

const ArrowDown = () => (
  <StyledIconContainer
    onClick={() =>
      typeof window !== undefined && document !== undefined
        ? window.scrollTo(0, document.documentElement.clientHeight)
        : null
    }
  >
    <img src={ArrowDownIcon} alt="arrow-down-icon" />
  </StyledIconContainer>
)

const StyledIconContainer = styled.div`
  cursor: pointer;
`

export default ArrowDown
