import React from "react"
import { Carousel } from "react-responsive-carousel"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import icon from "../../images/reviewBlock_icon.svg"
import { Colors } from "../../utils/constants"
import Review from "../Review/Review"

const ReviewBlock = ({ title, reviews = [] }) => {
  return (
    <StyledReviewBlockContainer>
      <div>
        {title && <h1>{title}</h1>}
        <img src={icon} alt="icon-review" />
      </div>
      <StyledCarousel
        infiniteLoop={true}
        autoPlay={true}
        showArrows={true}
        showStatus={false}
        showThumbs={false}
      >
        {
          /**
          We need to filter over the review to check for all the necessary fields
          The reason why we are not just returning null in the map is because
          The Carousel component dosen't accept null values
          We need to make sure that all the items are good before
          **/

          reviews
            .filter(
              ({ content: { text, name, position, slug }, fixedSources }) =>
                name && text && position && slug && fixedSources
            )
            .map(
              ({ content: { text, name, position, slug }, fixedSources }) => (
                <ReviewContainer key={slug}>
                  <Review
                    alt={`${name}'s review`}
                    fixedSources={fixedSources}
                  />
                  <StyledDescription>
                    <p>{text}</p>
                    <hr />
                    <h4>{name}</h4>
                    <h4>{position}</h4>
                  </StyledDescription>
                </ReviewContainer>
              )
            )
        }
      </StyledCarousel>
    </StyledReviewBlockContainer>
  )
}

const StyledCarousel = styled(Carousel)`
  ${media.md`
  width: 110%;
  `}
  width: 100%;
  margin-top: 50px;
  .control-dots {
    .dot {
      background-color: ${Colors.GREY_8};
    }
    .dot.selected {
      background-color: ${Colors.RED};
    }
  }
  .slide {
    background-color: ${Colors.WHITE};
  }

  .carousel {
    .control-prev.control-arrow::before {
      border: solid red;
      border-width: 0 6px 6px 0;
      padding: 10px;
      transform: rotate(135deg) skew(-10deg, -10deg);
      border-radius: 3px;
    }
    .control-next.control-arrow::before {
      border: solid red;
      border-width: 0 6px 6px 0;
      padding: 10px;
      transform: rotate(-44deg) skew(-10deg, -10deg);
      border-radius: 3px;
    }
    .carousel-slider {
      .control-arrow {
        display: none;
      }
    }
    .control-dots {
      .dot {
        box-shadow: 0px 1px 1px rgb(0 0 0 / 56%);
      }
    }
  }

  .carousel.carousel-slider {
    .control-arrow:hover {
      background: rgba(0, 0, 0, 0);
    }
  }

  ${media.xs`
  .carousel.carousel-slider {
    .control-arrow {
      display:none;
    }
  }`}

  .carousel .thumbs-wrapper {
    display: none;
  }
`

const StyledReviewBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > div {
    height: 100%;
  }
  & > div:first-of-type {
    text-align: center;
    img {
      margin: 20px 0;
    }
  }
  h1 {
    font-size: 14px;
    color: ${Colors.RED};
  }
  ${media.md`
    h1 {
        font-size: 30px; 
    }
  `}
`
const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  height: 100%;
  flex-basis: 100%;
  margin-bottom: 100px;
  * {
    flex-grow: 1;
    display: block;
  }
  hr {
    transform: rotate(90deg);
    width: 35px;
    margin: 35px auto;
    background: ${Colors.RED};
    border: 1px solid ${Colors.RED};
  }
  p {
    height: 70px;
    line-height: 1.8;
    color: ${Colors.GREY_6};
    max-width: 90%;
    ${media.md`
      max-width: 60%;
    `}
  }
  h4 {
    font-weight: bold;
    margin: 5px 0 0;
  }
  h4:last-of-type {
    color: ${Colors.RED};
  }
  ${media.md`
      font-size: 16px;
  `}
`

export default ReviewBlock
