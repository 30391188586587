import React from "react"
import styled from "styled-components"
import { Col, Row, Container, media } from "styled-bootstrap-grid"

import LinkButton from "../LinkButton/LinkButton"

const SocialMedia = ({ socialMedias }) => {
  return (
    <StyledSocialMedia>
      <Row>
        {socialMedias &&
          socialMedias.socialMedia.map(({ content, url, icon }) => (
            <Col col="8" md="4" lg="3" key={url}>
              <LinkButton
                href={url}
                icon={icon.file.url}
                theme="pink"
                stretched
              >
                {content}
              </LinkButton>
            </Col>
          ))}
      </Row>
    </StyledSocialMedia>
  )
}

const StyledSocialMedia = styled(Container)`
  margin-top: 62px;
  & > div {
    display: flex;
    justify-content: center;
  }
  & > div > div {
    margin-bottom: 10px;
  }
  a {
    padding: 16.5px 21.5px;
    line-height: 1.2;
    height: 100%;
    border-radius: 10px;
    ${media.md`& span {
      max-width: 96px;
    }`}
    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
`

export default SocialMedia
