import React from "react"
import { Container, Col, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"
import MediaObject from "../MediaObject/MediaObject"

const GetInTouch = ({ title, content, meetingCards, locations }) => {
  return (
    <>
      <StyledGetInTouchContainer>
        <Row>
          <Col sm="12" lg="8" lgOffset="2">
            {title && <h1>{title}</h1>}
          </Col>
        </Row>
        <StyledBackground>
          <Row>
            <Col>{content && <p>{content}</p>}</Col>
          </Row>
          {meetingCards && (
            <Row>
              {meetingCards.map(
                ({
                  icon,
                  title,
                  titleColor = Colors.RED_2,
                  description,
                  link,
                  backgroundColor = Colors.PINK,
                }) => (
                  <Col col="12" lg="6" key={title}>
                    <StyledMediaObject
                      icon={icon}
                      title={title}
                      titleColor={titleColor}
                      description={description}
                      link={{ theme: "red", ...link }}
                      backgroundColor={backgroundColor}
                    />
                  </Col>
                )
              )}
            </Row>
          )}
          {locations && (
            <StyledLocationContainer>
              <Row>
                {locations.map(
                  ({ country, address1, address2, zip, phone }) => (
                    <Col col="12" md="3" key={phone}>
                      {country && <h3>{country}</h3>}
                      <ul>
                        {address1 && <li>{address1}</li>}
                        {address2 && <li>{address2}</li>}
                        {zip && <li>{zip}</li>}
                        {phone && <li>{phone}</li>}
                      </ul>
                    </Col>
                  )
                )}
              </Row>
            </StyledLocationContainer>
          )}
        </StyledBackground>
      </StyledGetInTouchContainer>
    </>
  )
}

const StyledBackground = styled.div`
  background: ${Colors.GREY_3};
  padding: 0 20px;
  ${media.md`
    padding: 0 60px;
  `}
`

const StyledMediaObject = styled(MediaObject)`
  padding: 25px;
`

const StyledGetInTouchContainer = styled(Container)`
  h1,
  p {
    font-weight: bold;
  }
  h1 {
    color: ${Colors.RED};
    text-align: center;
    text-transform: uppercase;
  }
  p:first-child {
    font-size: 14px;
  }
  div:first-child {
    margin-bottom: 10px;
  }
  ${media.lg`
    div:first-child {
      margin-bottom: 0; 
    }
    p:first-child {
      font-size: 18px;
    }
  `}
`

const StyledLocationContainer = styled.div`
  margin-top: 70px;
  h3 {
    font-size: 18px;
    color: ${Colors.RED_1};
  }
  ul {
    padding: 0;
    list-style: none;
  }
  li {
    font-size: 14px;
    line-height: 2;
    color: ${Colors.GREY_2};
  }
`

export default GetInTouch
