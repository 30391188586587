import React from "react"
import styled from "styled-components"

import Image from "../Image/Image"

const Review = ({ fixedSources, alt }) => {
  return (
    <StyledReview>
      <Image fixedSources={fixedSources} alt={alt} />
    </StyledReview>
  )
}

const StyledReview = styled.div`
  img {
    cursor: pointer;
    border-radius: 50%;
    height: auto;
  }
`

export default Review
