import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import closeIcon from "../../images/close-icon.svg"
import questionIcon from "../../images/question-icon.svg"
import searchIcon from "../../images/search_icon.svg"
import { Colors } from "../../utils/constants"

const SideBar = ({
  title = "What is it you’re looking for?",
  searchPlaceholder = "Or type something you like to ask about!",
  sections,
  showSearch,
}) => {
  const [showSideBar, setShowSideBar] = React.useState(true)
  const [selectedValue, setSelectedValue] = React.useState(
    sections[0].sectionItems[0].question
  )
  return (
    <Container>
      <SideBarContainer active={showSideBar}>
        <SideBarHeader>
          <SideBarBtn onClick={() => setShowSideBar(!showSideBar)}>
            <img src={showSideBar ? questionIcon : closeIcon} alt="" />
          </SideBarBtn>
          <StyledSearch showSearch={showSearch}>
            <h2>{title}</h2>
            {showSearch && (
              <div>
                <img src={searchIcon} alt="Search icon" />
                <input type="search" placeholder={searchPlaceholder} />
              </div>
            )}
          </StyledSearch>
        </SideBarHeader>
        <SideList>
          {React.Children.toArray(
            sections.map(section => (
              <>
                <h2>{section.sectionTitle}</h2>
                {React.Children.toArray(
                  section.sectionItems.map(item => (
                    <li>
                      <Title
                        title={item.question}
                        selectedValue={selectedValue}
                        href={`#${item.question}`}
                        onClick={() => {
                          setSelectedValue(item.question)
                          setShowSideBar(!showSideBar)
                        }}
                      >
                        {item.question}
                      </Title>
                    </li>
                  ))
                )}
              </>
            ))
          )}
        </SideList>
      </SideBarContainer>
    </Container>
  )
}

const StyledSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${props => (props.showSearch ? "0px" : "50px")};
  z-index: 1;
  ${media.md`
    padding: 0 5% 0 15%;
  `}
  div {
    display: flex;
    border: 2px solid ${Colors.LIGHT_GREY};
    border-radius: 10px;
    padding: 15px 10px;
    background-color: ${Colors.WHITE};
  }
  input {
    border: none;
    outline: none;
    margin-left: 5px;
    flex: 1;
    font-size: 12px;
  }
`
const Container = styled.div`
  position: fixed;
  z-index: 1;
  width: auto;
  top: 0;
  left: 0;
  ${media.md`
    position: static;
  `}
`

const SideBarContainer = styled.div`
  position: relative;
  background-color: ${Colors.RED_3};
  z-index: 1;
  padding: 77px 21px 21px 21px;
  color: ${Colors.WHITE};
  transform: ${props =>
    props.active === true ? "translateX(-101%)" : "translateX(0)"};
  transition: transform 0.3s ease-in;
  width: 65vw;
  height: 100vh;

  ${media.md`
    position:static;
    height: auto;
    width:auto;
    display: flex;
    flex-direction: column;
    background-color:${Colors.WHITE};
    padding: 0px 0px 0px 5vw;
    margin: 15px 0px;
    color: ${Colors.BLACK};
    transform: translateX(0);
    transition: none;
  `};
`

const SideList = styled.ul`
  margin: 42px 0px 0px 0px;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  overflow-y: scroll;
  height: 600px;

  ${media.md`
  padding: 0 5% 0 15%;
  overflow: hidden;
  height:auto;
  `}
`

const SideBarHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;

  & > :first-child {
    order: 0;
    flex: 2 1 auto;
    align-self: auto;
    z-index: 1;
  }

  & > :last-child {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }
`

const SideBarBtn = styled.button`
  position: absolute;
  right: -43px;
  top: 130px;
  padding-left: 55px;
  width: 100px;
  height: 50px;
  background-color: ${Colors.RED};
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: none;
  outline: none;
  ${media.md`
    display:none;
  `};
`

const Title = styled.a`
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin: 25px 0px 15px 20px;
  color: ${Colors.WHITE};
  text-decoration: ${props =>
    props.title === props.selectedValue ? "underline" : "none"};
  cursor: pointer;
  line-height: 1.6;
  ${media.md`
    color: ${props =>
      props.title === props.selectedValue ? Colors.RED : Colors.GREY_12};
    text-decoration: none;
    &:hover {
      color: ${Colors.RED};
    }
  `};
`

export default SideBar
