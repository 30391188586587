import GatsbyImage from "gatsby-image"
import React, { useEffect, useState } from "react"

const MOBILE_QUERY = `(max-width: 767px)`
const TABLET_QUERY = `(max-width: 992px)`
const DESKTOP_QUERY = `(min-width: 993px)`

const Image = function (props) {
  const [fixedSources, setFixedSources] = useState()
  useEffect(() => {
    if (props.fixedSources) {
      const sources = [
        {
          media: MOBILE_QUERY,
          ...props.fixedSources.mobileImage,
        },
        {
          media: TABLET_QUERY,
          ...props.fixedSources.tabletImage,
        },
        {
          media: DESKTOP_QUERY,
          ...props.fixedSources.desktopImage,
        },
      ]
      setFixedSources(sources)
    }
  }, [setFixedSources, props.fixedSources])

  return props.fluid ? (
    <GatsbyImage {...props} />
  ) : fixedSources ? (
    <GatsbyImage fixed={fixedSources} {...props} />
  ) : null
}

export default Image
