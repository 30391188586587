import React from "react"
import styled from "styled-components"

import { Paragraph, Question } from ".."

const QuestionsBlock = ({ sections, pageTitle, pageDescription }) => {
  return (
    <>
      {(pageTitle || pageDescription) && (
        <Paragraph
          title={pageTitle}
          content={pageDescription}
          isContentBoldAndBlack
        />
      )}
      {React.Children.toArray(
        sections.map(section => (
          <>
            <h2>{section.sectionTitle}</h2>
            {section.sectionItems.map(item => (
              <StyledSectionItem key={item.slug}>
                <Anchor id={item.question} />
                <Question question={item.question} response={item.answer} />
              </StyledSectionItem>
            ))}
          </>
        ))
      )}
    </>
  )
}

const StyledSectionItem = styled.div`
  margin: 50px 30px;
`

const Anchor = styled.div`
  position: relative;
  top: -80px;
`

export default QuestionsBlock
