import React, { useState } from "react"
import styled, { css } from "styled-components"
import { media } from "styled-bootstrap-grid"

import { Colors } from "../../utils/constants"

const Point = ({
  title,
  icon,
  hoverIcon,
  onMouseLeave,
  onMouseEnter,
  selected,
  last,
}) => {
  const [displayedIcon, setDisplayedIcon] = useState(icon)

  const onHover = () => {
    setDisplayedIcon(hoverIcon)
    onMouseEnter && onMouseEnter()
  }

  const onHoverReset = () => {
    setDisplayedIcon(icon)
    onMouseLeave && onMouseLeave()
  }

  return (
    <StyledPoint
      onMouseLeave={onHoverReset}
      onMouseEnter={onHover}
      selected={selected}
      last={last}
    >
      {displayedIcon && (
        <img src={selected ? hoverIcon : displayedIcon} alt="timeLine-icon" />
      )}
      {title && <h2>{title}</h2>}
    </StyledPoint>
  )
}

const Timeline = ({ pointsInTime = [], fullTimeLine }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const selectedContent =
    pointsInTime[selectedIndex] && pointsInTime[selectedIndex].content

  return (
    <>
      <StyledTimeline>
        {pointsInTime.map(({ title, icon, hoverIcon, content }, index) => (
          <StyledContainer fullTimeLine={fullTimeLine} key={title}>
            <Point
              key={index}
              title={title}
              icon={icon}
              hoverIcon={hoverIcon}
              last={pointsInTime.length - 1 === index}
              selected={selectedIndex === index}
              onMouseEnter={() => setSelectedIndex(index)}
            />
            <StyledMobileDescription>{content}</StyledMobileDescription>
          </StyledContainer>
        ))}
      </StyledTimeline>

      <StyledDescription>{selectedContent}</StyledDescription>
    </>
  )
}

const StyledTimeline = styled.div`
  display: flex;
  flex-direction: column;
  ${media.md`
    flex-direction:row;
    margin-bottom: 0px;
    & > div:first-of-type img {
      margin-left: 0;
    }
    & > div:first-of-type h2 {
      padding-left: 0;
    }
  `};
`
const StyledContainer = styled.div`
  ${props =>
    !props.fullTimeLine &&
    css`
      &:nth-last-of-type(-n + 4) {
        display: none;
      }
    `}
  margin-bottom: 60px;
  ${media.md`
    &:nth-last-of-type(-n + 4) {
      display: block;
    }
    flex: 1;
    margin-bottom: 0px;
`};
`

const StyledPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    height: 30px;
  }
  h2 {
    width: 100%;
    padding-left: 15px;
    color: ${Colors.RED};
    font-size: 14px;
    line-height: 0px;
  }

  ${media.md`
    flex-direction: column;
    align-items: start;
    color: ${Colors.GREY_6};
    margin-top: 20px;
    font-size: 14px;
    cursor:pointer;
    h2{
      padding-right: ${props => (props.last ? "10px" : "0px")};
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 20px;
      color: ${props => (props.selected ? Colors.RED : Colors.GREY_11)};
      border-top: solid 3px ${props =>
        props.selected ? Colors.RED : "#efefef"};
    }
    &:hover h2 {
      border-color: ${Colors.RED};
      color: ${Colors.RED};
    } 
    img{
      margin-left: 20px;
    }
  `};
`
const StyledDescription = styled.div`
  display: none;
  ${media.md`
    display: block;
    color: ${Colors.GREY_6};
    margin-top: 20px;
    font-size: 18px;
  `};
`
const StyledMobileDescription = styled.div`
  font-size: 12px;
  margin-top: 10px;
  color: ${Colors.GREY_5};

  ${media.md`
    display: none;
  `};
`

export default Timeline
