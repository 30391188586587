import React from "react"
import { Col, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import LinkButton from "../LinkButton/LinkButton"
import MediaObject from "../MediaObject/MediaObject"
import Paragraph from "../Paragraph/Paragraph"

const MediaObjectGrid = ({
  link,
  paragraph,
  mediaObjects,
  col,
  mobileCol,
  isDescriptionInGrid
}) => {
  return (
    <StyledContainer>
      {paragraph && (
        <StyledRow>
          <Col md="6" col="12">
            <Paragraph
              title={paragraph.title}
              content={!isDescriptionInGrid && paragraph.content}
            />
          </Col>
        </StyledRow>
      )}
      <Row>
        {isDescriptionInGrid && (
          <Col md={col ? 12 / col : "6"} col="12">
            {paragraph && <StyledParagraph content={paragraph.content} />}
          </Col>
        )}
        {mediaObjects.map(({ titleColor, icon, title, description }) => (
          <Col
            key={title}
            col="6"
            xs={mobileCol ? 12 / mobileCol : "12"}
            md={col ? 12 / col : "6"}
          >
            <MediaObject
              icon={icon}
              title={title}
              titleColor={titleColor}
              description={description}
            />
          </Col>
        ))}
      </Row>
      {link && (
        <StyledLinkButtonRow>
          <StyledButton theme={link.theme} href={link.href}>
            {link.label}
          </StyledButton>
        </StyledLinkButtonRow>
      )}
    </StyledContainer>
  )
}

const StyledLinkButtonRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`
const StyledContainer = styled.div`
  padding: 50px 0px;
  ${media.md`
  padding-bottom: 86px;
 `}
`
const StyledRow = styled(Row)`
  margin-bottom: 20px;
  ${media.md`
   margin-bottom: 60px;
  `}
`

const StyledButton = styled(LinkButton)`
  margin-top: 65px;
  ${media.md`
   margin-top: 55px;
  `}
`
const StyledParagraph = styled(Paragraph)`
  p {
    margin-top: 0px;
  }
`

export default MediaObjectGrid
