import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import { Colors } from "../../utils/constants"

const Question = ({ response, question }) => {
  return (
    <StyledQuestion>
      {question && <h4>{question}</h4>}
      {response && <p>{response}</p>}
    </StyledQuestion>
  )
}

const StyledQuestion = styled.div`
  text-align: left;
  margin-bottom: 50px;
  h4 {
    color: ${Colors.RED};
    font-size: 14px;
  }
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
  ${media.md`
    h4,
    p {
      font-size: 16px
    }
  `}
`

export default Question
