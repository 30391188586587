import React from "react"
import { media } from "styled-bootstrap-grid"
import styled from "styled-components"

import Image from "../Image/Image"
import Paragraph from "../Paragraph/Paragraph"

const compare = (a, b) => {
  const ratiosA = a.ratios
  const ratiosB = b.ratios

  let comparison = 0
  if (ratiosA < ratiosB) {
    comparison = 1
  } else if (ratiosA > ratiosB) {
    comparison = -1
  }
  return comparison
}

const Gallery = ({ paragraph, photos, isVertical, alignRight, centerV }) => {
  return (
    <StyledGalleryContainer isVertical={isVertical}>
      {paragraph && (paragraph.title || paragraph.content) && (
        <StyledParagraph
          title={paragraph.title}
          content={paragraph.content}
          alignRight={alignRight}
          centerV={centerV}
        />
      )}
      <StyledItemsContainer isVertical={isVertical}>
        {photos &&
          photos
            .sort(compare)
            .map(({ ratios, img, fixedSources }, index) => (
              <StyledImg
                key={index}
                ratios={ratios}
                fluid={img}
                fixedSources={fixedSources}
                alt="gallery-item"
              />
            ))}
      </StyledItemsContainer>
    </StyledGalleryContainer>
  )
}

const StyledParagraph = styled(Paragraph)`
  ${media.md`
    align-self: ${props =>
      props.alignRight ? "flex-end" : props.centerV ? "center" : "auto"};
    text-align: ${props => (props.alignRight ? "right" : "left")};
    width: ${props =>
      props.alignRight ? "40vw" : props.centerV ? "50%" : "auto"};
    flex:1;
  `}
`

const StyledGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md`
        flex-direction:${props =>
          props.isVertical ? "column" : "row-reverse"};
    `}
`

const StyledItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${props => (props.isVertical ? "0" : "-5%")};
  ${media.md`
  width:50%;
    `}
`

const StyledImg = styled(Image)`
  margin: 5px;
  border-radius: 5%;
  width: ${props => props.ratios * 100 + "%"};
  height: auto;
`

export default Gallery
